*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

input {
  font-size: 16px !important;
}

input:focus {
  font-size: 16px !important;
}

html {
  font-size: 62.5%;
}

body {
  font-family: acumin-pro-wide, sans-serif;
  font-weight: 700;
  line-height: 1.7;
  font-style: normal;
  color: #777;
  padding: 0 1rem;
  box-sizing: border-box;
  animation-name: fadeIn;
  animation-duration: 1s;
  position: relative;
}

@media only screen and (max-width: 600px) {
  body {
    padding: 1rem 0.5rem;
  }
}
