.display-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  margin: 2rem 0;
  padding: 0 1rem;
  min-height: 95vh;
  padding: 6.5rem 0;
  margin-bottom: 5rem;

  @media only screen and (max-width: 600px) {
    & {
      margin: 0;
    }
  }
}
