.article {
  width: 40rem;
  padding: 1rem;

  &:hover {
    box-shadow: 2px 2px 2px rgba(black, 0.5);
    transition: 0.3s;
  }

  & a {
    text-decoration: none;
    color: black;
  }

  &__text {
    display: flex;
    flex-direction: column;
    padding: 0 0.5rem;
  }

  &__title {
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
  }

  &__subtitle {
    color: grey;
  }

  &__date {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
    color: grey;
  }

  &__image-container {
    width: 100%;
  }

  @media only screen and (max-width: 600px) {
    & {
      width: 100%;
      padding: 0.5rem 0.5rem;
      margin-top: 3rem;
    }
  }

  @media only screen and (min-width: 600px) {
    & {
      width: 50%;
    }
  }

  @media only screen and (min-width: 1000px) {
    & {
      width: 33%;
    }
  }

  @media only screen and (min-width: 1000px) {
    & {
      width: 33%;
    }
  }

  @media only screen and (min-width: 1400px) {
    & {
      width: 25%;
    }
  }
}
