.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 8rem;
  padding: 0 1rem;
  padding-top: 0.5rem;
  position: fixed;

  &--active {
    background-color: white;
    transition: ease-in 0.5s;
    box-shadow: 0 4px 2px -2px gray;
  }

  & a {
    text-decoration: none;
    color: black;
    transition: 0.3s;
  }

  &__logo {
    padding-top: 0.5rem;
    font-size: 2.5rem;
    animation-name: moveFromLeft;
    animation-duration: 1.5s;

    & a {
      text-decoration: underline;
      color: #00ff7f;
    }
  }

  &__underline-color {
    color: black;
    &:hover {
      color: gray;
      transition: 0.2s ease-in-out;
    }
  }

  &__navbar {
    display: flex;
  }

  &__navbar-li {
    display: inline-block;
    padding: 0 1rem;
    margin-top: 15px;

    &:nth-child(-n + 6) {
      font-size: 1.8rem;
      & a:hover {
        color: grey;
      }
    }
  }

  &__menu {
    display: none;
    cursor: pointer;
  }

  &__exit {
    display: none;
  }

  @media (hover: hover) and (pointer: fine) {
    .header a:hover {
      color: gray;
    }
  }

  @media only screen and (max-width: 1190px) {
    &__navbar-mobile {
      position: absolute;
      top: 0;
      right: 0;
      height: 100vh;
      width: 50%;
      background-color: white;
      letter-spacing: 0.15rem;
      border: 2px solid rgb(54, 54, 54);
      margin-top: 1rem;
      box-shadow: 0px 3px 8px rgb(100, 100, 100);

      &--hidden {
        transform: translateX(1000px);
      }

      &--visible {
        transform: translateX(0px);
        transition: 0.3s;
      }

      @media only screen and (max-width: 650px) {
        & {
          width: 75%;
        }
      }

      & ul {
        height: 100vh;
        padding-top: 5rem;
        height: 25%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;

        & li {
          margin: 10px 2px;
          width: 100%;
          text-align: center;
          position: relative;
          padding: 0.5rem 0;

          &:nth-child(-n + 6) {
            border-bottom: 2px solid black;
            letter-spacing: 3px;
          }

          &:nth-child(7) {
            flex-basis: 100%;
          }

          &::nth-child(6) button {
            height: 2.5rem;
          }

          & a {
            color: black;
          }
        }
      }
      @media screen {
      }
    }

    &__menu {
      display: block;
      color: black;

      &--disabled {
        display: none;
      }
    }

    &__exit {
      &--enabled {
        display: block;
        cursor: pointer;
        z-index: 100;
        color: black;
      }
    }
  }
}

footer {
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 25px;
  padding-top: 50px;
  transform: translate(-50%);
  z-index: -1000;
}
