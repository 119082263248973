.banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25vh;
  background: black;
  color: white;
  font-size: 1.25rem;
  font-family: Helvetica;
  letter-spacing: 2px;
  padding: 0 2rem;
  border-radius: 2px;

  &__content-wrapper {
    display: flex;
    justify-content: space-around;
    max-width: 800px;
    margin: 0 auto;
  }

  @media only screen and (min-width: 600px) {
    padding-top: 3rem;
  }

  @media only screen and (min-width: 800px) {
    padding-top: 5rem;
  }

  &--hidden {
    display: none;
  }

  &__text {
    width: 60%;
    padding-top: 2rem;
    font-weight: 400;
  }

  &__button-container {
    align-self: center;
    margin: 0 auto;
  }

  &__button {
    padding: 1rem;
    border-radius: 6px;
    width: 100%;
    letter-spacing: 2px;
    outline: none;
    background-color: black;
    color: white;
    font-size: 1.6rem;
    font-weight: 400;
    border: 1px solid white;

    &:hover {
      cursor: pointer;
      background-color: white;
      color: black;
      transition: 0.2s;
    }
  }
}
